export default [{
    justMobile: false,
    title: 'DashBoard',
    route: 'dashBoard',
    icon: 'fa-solid fa-house',
}, {
    justMobile: true,
    title: 'Cari Kartlar',
    icon: 'fa-solid fa-users',
    tagVariant: 'light-warning',
    children: [{
        title: 'Cari Gruplar',
        route: 'cariGruplar',
    }, {
        title: 'Cari Kartlar',
        route: {
            name: 'cariListe',
            params: { id: 0 },
        },
    },],
}, {
    justMobile: true,
    title: 'Stoklar',
    icon: 'boxes-stacked',
    tagVariant: 'light-warning',
    children: [{
        title: 'Stok Grupları',
        route: 'stokGrup',
    }, {
        title: 'Stok Listesi',
        route: {
            name: 'stokListesi',
            params: { id: 0 },
        },
    },],
}, {
    justMobile: true,
    title: 'Satışlar',
    icon: 'truck-ramp-box',
    tagVariant: 'light-warning',
    children: [{
        title: 'Yeni Satış Oluştur',
        route: 'yeniSatis',
    }, {
        title: 'Satış Faturaları',
        route: { name: 'satislar' },
    }, {
        title: 'Proformalar',
        route: { name: 'proformalar' },
    },],
}, {
    justMobile: true,
    title: 'Giderler',
    icon: 'minus',
    tagVariant: 'light-warning',
    children: [{
        title: 'Yeni Alış Faturası',
        route: 'yeniAlis',
    }, {
        title: 'Alış Faturaları',
        route: { name: 'alislar' },
    }, {
        title: 'Gider Grupları',
        route: { name: 'giderGruplar' },
    }, {
        title: 'Gideler',
        route: {
            name: 'giderListesi',
            params: { id: 0 },
        },
    },],
}, {
    justMobile: true,
    title: 'Depolar',
    icon: 'warehouse',
    tagVariant: 'light-warning',
    children: [{
        title: 'Depo Listesi',
        route: 'depolar',
    },],
}, {
    justMobile: true,
    title: 'Kasa ve Banka',
    icon: 'building-columns',
    tagVariant: 'light-warning',
    children: [{
        title: 'Kasa',
        route: 'kasa',
    }, {
        title: 'Banka Havaleleri',
        route: 'banka',
    },],
}, {
    justMobile: true,
    title: 'Raporlar',
    icon: 'clipboard-list',
    tagVariant: 'light-warning',
    children: [{
        title: 'Rapor Oluştur',
        route: 'rapor',
    },],
}, {
    justMobile: false,
    title: 'Servis İşlemleri',
    icon: 'fa-solid fa-gear',
    tagVariant: 'light-warning',
    children: [{
        title: 'Kullanıcı Yönetimi',
        route: 'kullaniciListesi',
    }, {
        title: 'E-Fatura Ayarları',
        route: 'eFatura',
    },],
}, {
    justMobile: false,
    title: 'İhale İşlemleri',
    icon: 'fa-solid fa-gavel',
    tagVariant: 'light-warning',
    children: [{
        title: 'İhale Oluştur',
        route: 'ihaleOlustur',
        icon: 'fa-solid fa-plus',
    }, {
        title: 'İhale Listesi',
        route: 'ihaleListesi',
        icon: 'fa-solid fa-list',
    }, {
        title: 'Komisyon Üyeleri',
        route: 'komisyonUyeleri',
        icon: 'fa-solid fa-users',
    }, {
        title: 'Firma Listesi',
        route: 'firmaListesi',
        icon: 'fa-solid fa-building',
    },],
}, {
    justMobile: false,
    title: 'Salon Yönetimi',
    icon: 'fa-solid fa-calendar-days',
    tagVariant: 'light-warning',
    children: [{
        title: 'Özet Görünüm',
        route: 'ozetGorunum',
        icon: 'fa-solid fa-solar-panel',
    }, {
        title: 'Rezervasyon Listesi',
        route: 'rezervasyonListesi',
        icon: 'fa-solid fa-handshake',
    }, {
        title: 'Salon Listesi',
        route: 'salonListesi',
        icon: 'fa-solid fa-list',
    },],
},]
