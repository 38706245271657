<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class=" font-weight-bolder mb-0">
          {{ userName }}
        </p>
        <span class="user-status">{{ rol }}</span>
      </div>
      <b-avatar
        size="40"
        src="require('https://demopanel.oys.web.tr/app-assets/images/portrait/small/avatar.png')"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      v-b-modal.root-sifre-degis
    >
      <feather-icon
        size="16"
        icon="EditIcon"
        class="mr-50"
      />
      <span>Şifre Güncelle</span>
    </b-dropdown-item>
    <b-dropdown-divider/>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="PowerIcon"
        class="mr-50"
      />
      <span>Çıkış Yap</span>
    </b-dropdown-item>
    <b-modal
      id="root-sifre-degis"
      ok-title="Kaydet"
      :ok-disabled="sifre.length === 0"
      modal-class="modal-primary"
      centered
      title="Şifre Değiştir"
      cancel-variant="outline-secondary"
      cancel-title="Vazgeç"
      @ok="sifreDegis"
      @hidden="sifre = ''"
    >
      <b-form-group
        label="Şifre"
        label-for="basicInput"
      >
        <b-form-input
          id="basicInput"
          v-model="sifre"
          autocomplete="off"
        />
      </b-form-group>
    </b-modal>
  </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BModal, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
    components: {
        BFormInput,
        BFormGroup,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,
        BModal,
    },
    data() {
        return {
            userData: JSON.parse(localStorage.getItem('userData')),
            avatarText,
            userName: '',
            rol: '',
            sifre: '',
        }
    },
    computed: {},
    mounted() {
        this.userName = localStorage.getItem('buroFullName')
        this.rol = this.$store.getters.getRole == 1 ? 'Yönetici' : 'Personel'
    },
    methods: {
        sifreDegis() {
            const { sifre } = this
            this.$store.dispatch('sifreDegisRoot', sifre)
                .then((res, position = 'bottom-right') => {
                    if (res.status == 200) {
                        this.$store.dispatch('logIn', {
                            pwd: sifre,
                            firmaKodu: localStorage.getItem('buroFirmaKodu'),
                            user: localStorage.getItem('buroUserName'),
                        })
                        const mesaj = this.$store.getters.notificationSettings(res)
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Başarılı',
                                text: mesaj,
                                icon: 'ThumbsUpIcon',
                                variant: 'success',
                            },
                        }, { position })
                    }
                })
                .catch(err => {
                    const mesaj = this.$store.getters.notificationSettings(err)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                        },
                    })
                })
            this.sifre = ''
        },
        logout() {
            store.state.isAuth = false
            store.commit('removeToken')
            store.commit('removeUserData')
            this.$router.push({ name: 'login' })
            localStorage.removeItem('buroUserData')
            localStorage.removeItem('buroFirmaKodu')
            localStorage.removeItem('buroUserName')
            this.$ability.update(initialAbility)
        },
    },
}
</script>

<style scoped>
.b-avatar.badge-light-primary {
    color: white !important;
}
</style>
