<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header unPrint"
  >
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h3 class="content-header-title float-left pr-1 mb-0 dashboard-icon" @click="$router.push('/')">
            <!--            {{ $route.meta.pageTitle }}-->
            <font-awesome-icon icon="fa-solid fa-home"/>
          </h3>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <font-awesome-icon :class="{'top-34':$route.meta.altPage}" icon="fa-solid fa-chevron-right"
                                 class="fa-sm ml-50 my-left-icon top-34"
              />
              <b-breadcrumb-item
                class="breadcrumb-list breadcrumb-custom-padding"
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                <div v-if="!item.third && !item.dash">
                  <p class="d-inline-block">{{ item.first }}</p>
                  <font-awesome-icon :class="{'top-34':$route.meta.altPage}" icon="fa-solid fa-chevron-right"
                                     class="fa-xs mr-50 ml-50"
                  />
                  <p class="d-inline-block">{{ item.second }}</p>
                </div>
                <div v-else>
                  <div v-if="!item.faturaDetay">
                    <p class="d-inline-block">{{ item.first }}</p>
                    <font-awesome-icon v-if="item.first" :class="{'top-34':$route.meta.altPage}"
                                       icon="fa-solid fa-chevron-right"
                                       class="fa-xs mr-50 ml-50"
                    />
                    <a class="d-inline-block" href="javascript:void(0)"
                       @click="$router.push({ name: item.secondLink, params: { id: 0 } })"
                    >
                      {{ item.second }}
                    </a>
                    <font-awesome-icon v-if="item.second" :class="{'top-34':$route.meta.altPage}"
                                       icon="fa-solid fa-chevron-right"
                                       class="fa-xs mr-50 ml-50"
                    />
                    <p class="d-inline-block" v-if="!item.fourth">{{ item.third }}</p>
                    <a class="d-inline-block" href="javascript:void(0)"
                       v-else
                       @click="$router.go(-1)"
                    >
                      {{ item.third }}
                    </a>
                    <font-awesome-icon v-if="item.third" :class="{'top-34':$route.meta.altPage}"
                                       icon="fa-solid fa-chevron-right"
                                       class="fa-xs mr-50 ml-50"
                    />
                    <p class="d-inline-block">{{ item.fourth }}</p>
                  </div>
                  <div v-else>
                    <p class="d-inline-block">
                      {{ $store.getters.FATURA_DETAY_GET.faturaOzeti.faturaTuru == 1 ? 'Satışlar' : 'Giderler' }}</p>
                    <font-awesome-icon v-if="item.first" :class="{'top-34':$route.meta.altPage}"
                                       icon="fa-solid fa-chevron-right"
                                       class="fa-xs mr-50 ml-50"
                    />
                    <a class="d-inline-block" href="javascript:void(0)"
                       @click="$router.push({ name: $store.getters.FATURA_DETAY_GET.faturaOzeti.faturaTuru == 1 ? 'satislar':'alislar', params: { id: 0 } })"
                    >
                      {{
                        $store.getters.FATURA_DETAY_GET.faturaOzeti.faturaTuru == 1 ? 'Satış Faturaları' : 'Alış Faturaları'
                      }}
                    </a>
                    <font-awesome-icon v-if="item.second" :class="{'top-34':$route.meta.altPage}"
                                       icon="fa-solid fa-chevron-right"
                                       class="fa-xs mr-50 ml-50"
                    />
                    <p class="d-inline-block">{{ item.third }}</p>
                  </div>
                </div>
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <!--      <vue-apex-charts type="bar" height="120" :options="chartOptions" :series="series"-->
      <!--                       class="d-inline-block app-bread"-->
      <!--      />-->
      <!--      <div class="d-inline-block">-->
      <!--        <small class="d-block">-->
      <!--          Bekleyen Tahsilatlar-->
      <!--        </small>-->
      <!--        <strong class="float-left">10.000 TL</strong>-->
      <!--      </div>-->
      <!--      <div class="d-inline-block">-->
      <!--        <small class="d-block">-->
      <!--          Bekleyen Ödemeler-->
      <!--        </small>-->
      <!--        <strong class="float-left">10.000 TL</strong>-->
      <!--      </div>-->
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
            :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo + '!important', border:'none'}"
          >
            <feather-icon icon="SettingsIcon"/>
          </b-button>
        </template>
        <b-dropdown-item :to="{ name: 'yeniSatis' }">
          <font-awesome-icon icon="fa-solid fa-plus"/>
          <span class="align-middle ml-50">Satış Faturası</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{ name: 'yeniAlis' }">
          <font-awesome-icon icon="fa-solid fa-plus"/>
          <span class="align-middle ml-50">Alış Faturası</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{ path:'/cariListe/add'}">
          <font-awesome-icon icon="fa-solid fa-user"/>
          <span class="align-middle ml-50">Yeni Cari</span>
        </b-dropdown-item>
        <b-dropdown-item :to="{ path:'/stokListesi/add'}">
          <font-awesome-icon icon="fa-solid fa-barcode"/>
          <span class="align-middle ml-50">Yeni Stok</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import {
    BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton, BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import store from '@/store'
import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        BCardHeader,
        BCardBody,
        VueApexCharts,
        BCard,
        BBreadcrumb,
        BBreadcrumbItem,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BButton,
    },
    data() {
        return {
            series: [{
                name: '₺',
                data: [2.3, 3.1, 4.0, 10.1, 4.0, 3.6, 3.2, 2.3, 1.4, 0.8, 0.5, 0.2],
            }],
            chartOptions: {
                grid: {
                    showHorizontalLines: false,
                    showVerticalLines: false,
                },
                chart: {
                    height: 150,
                    type: 'bar',
                },
                dataLabels: {
                    enabled: false,
                    formatter(val) {
                        return `${val} %`
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '10px',
                        colors: ['#304758'],
                    },
                },
                xaxis: {
                    lines: {
                        show: false,
                    },
                    categories: ['', '', '', '', '', ''],
                    position: 'top',
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        fill: {
                            type: 'gradient',
                            gradient: {
                                colorFrom: '#D8E3F0',
                                colorTo: '#BED1E6',
                                stops: [0, 100],
                                opacityFrom: 0.4,
                                opacityTo: 0.5,
                            },
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                yaxis: {
                    axisBorder: {
                        show: false,
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter(val) {
                            return `${val} %`
                        },
                    },
                    lines: {
                        show: false,
                    },
                },
                title: {
                    floating: true,
                    offsetY: 330,
                    align: 'center',
                    style: {
                        color: '#444',
                    },
                },
                plotOptions: {
                    bar: {
                        barDistance: 12,
                        columnWidth: 40,
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top',
                        },
                    },
                },
            },
        }
    },
    methods: {
        router() {
            return router
        },
    },
    directives: {
        Ripple,
    },
}
</script>

<style>
.app-bread .apexcharts-toolbar {
    display: none !important;
}

.breadcrumb-list {
    margin-left: 2.5% !important;
}

.my-left-icon {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.top-34 {
    top: 34% !important;
}

.dashboard-icon {
    cursor: pointer !important;
    padding-right: 0 !important;
    border-right: none !important;
}

.content-header {
    height: 50px !important;
}

@media screen and (max-width: 1000px) {
    .breadcrumb-custom-padding {
        padding-left: 0.9rem !important;
    }
}
</style>
